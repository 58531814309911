import cx from 'classnames';
import { ComponentPropsWithoutRef, CSSProperties, PropsWithChildren } from 'react';

import { CssVariable } from '@hubcms/domain-styling';
import { AreaScroll, GList, GSingleItem, GTitle } from '@hubcms/domain-teaser-grid';
import { List } from '@hubcms/ui-list';
import { getThemeDataAttributes } from '@hubcms/utils-theme';

import { useAreaTitle } from '../../hooks/useAreaTitle';
import { GridElement } from '../../types/GridElement';
import { parseItemData } from '../../utils/parseItemData';
import { isScrollWithAtLeastOneBreakpoint } from '../../utils/isScrollWithAtLeastOneBreakpoint';
import { ScrollableItemWrapper, ScrollWrapper } from '../ScrollWrapper';
import { useTeaserGridExtensionsContext } from '../TeaserGridExtensions';

import styles from './teaser-list.module.scss';

type ListDirection = 'row' | 'column';

type TeaserListProps = {
  title?: GTitle;
  items: GSingleItem[];
  hasItemSeparator?: boolean | { xs: boolean; md: boolean; lg: boolean };
  listDirection?: ListDirection | { xs: ListDirection; md: ListDirection; lg: ListDirection };
  scroll?: GList['data']['scroll'];
  listProps: ComponentPropsWithoutRef<typeof List>;
  theme?: string;
  isTransparentTheme?: boolean;
};

function TeaserList({
  listDirection = 'column',
  hasItemSeparator = false,
  scroll,
  title,
  items,
  listProps,
  theme,
  isTransparentTheme,
}: TeaserListProps) {
  const { extensions } = useTeaserGridExtensionsContext();

  const { titleElement } = useAreaTitle(title, { className: styles.teaserListTitle });

  const listDirectionSm = typeof listDirection === 'string' ? listDirection : listDirection.xs;
  const listDirectionMd = typeof listDirection === 'string' ? listDirection : listDirection.md;
  const listDirectionLg = typeof listDirection === 'string' ? listDirection : listDirection.lg;
  const cssVariables: CssVariable = {
    '--i-flex-direction': listDirectionSm,
    '--i-flex-direction-md': listDirectionMd,
    '--i-flex-direction-lg': listDirectionLg,
  };
  const themeDataAttributes = theme ? getThemeDataAttributes(theme) : undefined;
  const divider = hasItemSeparator ? <li className={styles.listDivider} aria-hidden /> : null;
  const hasOpaqueTheme = !!theme && theme !== 'none' && !isTransparentTheme;

  const TeaserListWrapper =
    scroll && isScrollWithAtLeastOneBreakpoint(scroll)
      ? ScrollWrapper
      : // eslint-disable-next-line react/jsx-no-useless-fragment
        ({ children }: PropsWithChildren) => <>{children}</>;

  const listElements = items
    .map((item, itemIndex) => parseItemData(item, itemIndex, extensions))
    .filter((gridElement): gridElement is GridElement => Boolean(gridElement?.Component))
    .map((element, elementIndex, elementsArray) => {
      const { Component, key, componentProps, gridProps } = element;

      const hasDivider = elementIndex !== elementsArray.length - 1 && !gridProps?.hasNoGridDivider;
      const style: CSSProperties = {
        minWidth: typeof gridProps?.minWidth === 'string' ? gridProps?.minWidth : gridProps?.minWidth?.sm,
      };

      if (hasOpaqueTheme) {
        style.backgroundColor = 'var(--semantic-background-contextual-adaptive-default-fill)';
      }

      return (
        <ScrollableItemWrapper
          index={elementIndex}
          key={key}
          render={itemRef => (
            <>
              <List.Item
                className={cx({ [styles.withInverseMargin]: gridProps?.hasInverseMargin || gridProps?.hasInverseMarginSm })}
                data-testid={`teaser-rank-${elementIndex + 1}`}
                isLastofColumn={Math.floor(elementsArray.length / 2) === elementIndex}
                myRef={itemRef}
                style={style}
                {...themeDataAttributes}
              >
                <Component {...componentProps} />
              </List.Item>

              {hasDivider && divider}
            </>
          )}
        />
      );
    });

  if (!listElements.length) {
    return null;
  }

  return (
    <>
      {titleElement}

      <TeaserListWrapper areaScroll={scroll as AreaScroll} {...themeDataAttributes}>
        <List
          {...listProps}
          className={cx(styles.teaserList, {
            [styles.withSeparatorSm]: hasItemSeparator === true || (typeof hasItemSeparator === 'object' && hasItemSeparator?.xs),
            [styles.withSeparatorMd]: hasItemSeparator === true || (typeof hasItemSeparator === 'object' && hasItemSeparator?.md),
            [styles.withSeparatorLg]: hasItemSeparator === true || (typeof hasItemSeparator === 'object' && hasItemSeparator?.lg),
            [styles.listDirectionRowSm]: listDirectionSm === 'row',
            [styles.listDirectionRowMd]: listDirectionMd === 'row',
            [styles.listDirectionRowLg]: listDirectionLg === 'row',
          })}
          style={cssVariables}
        >
          {listElements}
        </List>
      </TeaserListWrapper>
    </>
  );
}

export default TeaserList;
